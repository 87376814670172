import { Injectable } from '@angular/core';
import {
  HotToastService,
  ObservableMessages,
  ValueOrFunction,
} from '@ngneat/hot-toast';
import { Content } from '@ngneat/overview';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotiService {
  constructor(
    private _toast: HotToastService,
    private _translocoService: TranslocoService,
  ) {}

  success(message: string) {
    this._toast.success(message);
  }

  successIntl(key: string, params: any = {}) {
    this._intlMessage(key, (mes) => this._toast.success(mes), params);
  }

  stdSuccessIntl() {
    this._intlMessage('common.noti.saved', (mes) => this._toast.success(mes));
  }

  error(message: string) {
    this._toast.error(message, {
      duration: 8000,
    });
  }

  errorIntl(key: string, params: any = {}) {
    this._intlMessage(
      key,
      (mes) =>
        this._toast.error(mes, {
          duration: 8000,
        }),
      params,
    );
  }

  stdErrorIntl() {
    this._intlMessage('common.noti.error', (mes) =>
      this._toast.error(mes, {
        duration: 8000,
      }),
    );
  }

  infoShortIntl(key: string) {
    this._intlMessage(key, (mes) =>
      this._toast.show(mes, {
        duration: 4500,
        icon: '💡',
        className:
          'border-b-2 border-primary toast rounded-lg dark:bg-dwhite dark:text-gray-900',
      }),
    );
  }

  info(message: string) {
    this._toast.show(message, {
      duration: 8000,
      icon: '💡',
      className:
        'border-b-2 border-primary toast rounded-lg dark:bg-dwhite dark:text-gray-900',
    });
  }

  infoIntl(key: string, params: any = {}) {
    this._intlMessage(
      key,
      (mes) =>
        this._toast.show(mes, {
          duration: 8000,
          icon: '💡',
          className:
            'border-b-2 border-primary toast rounded-lg dark:bg-dwhite dark:text-gray-900',
        }),
      params,
    );
  }

  warning(message: string) {
    this._toast.warning(message);
  }

  warningIntl(key: string, params: any = {}) {
    this._intlMessage(key, (mes) => this._toast.warning(mes), params);
  }

  observe({
    loading,
    success,
    error,
  }: Partial<ObservableMessages<unknown, unknown>> = {}) {
    return this._toast.observe({
      loading:
        loading ||
        this._translocoService.translate('notifications.default.saving'),
      success:
        success ||
        this._translocoService.translate<ValueOrFunction<Content, unknown>>(
          'notifications.default.successSaved',
        ),
      error:
        error ||
        this._translocoService.translate('notifications.default.error'),
    });
  }

  observeIntl<T, DataType>({
    loading,
    success,
    error,
  }: Partial<{ loading: string; success: string; error: string }> = {}): (
    source: Observable<T>,
  ) => Observable<T> {
    return this._toast.observe<T, DataType>({
      loading: loading
        ? this._translocoService.translate(loading)
        : this._translocoService.translate('notifications.default.saving'),
      success: success
        ? this._translocoService.translate<ValueOrFunction<Content, unknown>>(
            success,
          )
        : this._translocoService.translate<ValueOrFunction<Content, unknown>>(
            'notifications.default.successSaved',
          ),
      error: error
        ? this._translocoService.translate(error)
        : this._translocoService.translate('notifications.default.error'),
    });
  }

  private _intlMessage(
    key: string,
    fn: (message: string) => void,
    params: any = {},
  ) {
    this._translocoService
      .selectTranslate(key, params)
      .pipe(first())
      .subscribe((mes) => fn(mes));
  }
}
